/* LoadingDots.css */
.loading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading span {
  display: block;
  width: 4px; /* Diameter of the dots */
  height: 4px; /* Diameter of the dots */
  background-color: #636363; /* Dot color */
  border-radius: 50%; /* Makes the dots rounded */
  margin: 0 2px; /* Spacing between dots */
  animation: l 3s infinite;
}

.loading span:nth-child(1) {
  animation-delay: 0s;
}

.loading span:nth-child(2) {
  animation-delay: 0.5s;
}

.loading span:nth-child(3) {
  animation-delay: 1s;
}

/* Custom style for the MapLibre popup */
.custom-popup .maplibregl-popup-content {
  border-radius: 12px !important; /* Rounded corners */
  padding: 12px !important; /* Less padding */
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

@keyframes l {
  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5); /* Optional: Make the dots pulse */
  }
}
